<template>
  <div class="header">
    <v-app-bar color="indigo" height="90" dark flat>
      <div class="text-center">
        <v-img
          hidden
          src="@/assets/openkey.png"
          heigth="2"
          max-width="160"
          contain
        >
        </v-img>
      </div>
      <v-spacer></v-spacer>
			<div v-if="neighborhood" style="width:100%;text-align:center">{{ neighborhood.name }}</div>

      <v-btn v-if="false" icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
  }),

  computed: {
    ...mapGetters({
      neighborhood: "neighborhood",
    })
  },
  
  methods: {
  },
};
</script>
