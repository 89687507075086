import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex)
Vue.use(VueAxios, axios)

export default new Vuex.Store({
  state: {
    neighborhood: null,
    callees: null,
    cc: null,
    call: null,
  },
  getters: {
    neighborhood(state) {
      return state.neighborhood;
    },
    callees(state) {
      return state.callees;
    },
    cc(state) {
      return state.cc;
    },
    call(state) {
      return state.call;
    },
  },
  mutations: {
    SET_NEIGHBORHOOD(state, value) {
      state.neighborhood = value;
    },
    SET_CALLEES(state, value) {
      state.callees = value;
    },
    SET_CC(state, value) {
      state.cc = value;
    },
    SET_CALL(state, value) {
      state.call = value;
    }
  },
  actions: {
    async fetchData({ commit }, params) {
      await Vue.axios.get("https://us-central1-tappbellopenkey.cloudfunctions.net/getCallingData?token=" + params.token + "&idgroup=" + params.idgroup)
      .then((response) => {
          if (response.status == 200) {
            //console.log('Response OK 200');
            commit("SET_NEIGHBORHOOD", response.data.data.neighborhood);
            commit("SET_CALLEES", response.data.data.callees);
            commit("SET_CC", response.data.data.cc);
          } else {
            //console.log('Response Error 404');
            commit("SET_NEIGHBORHOOD", null);
            commit("SET_CALLEES", null);
            commit("SET_CC", null);
          }
      })
      .catch((error) => {
        //console.log('catched error', error);
        commit("SET_NEIGHBORHOOD", null);
        commit("SET_CALLEES", null);
        commit("SET_CC", null);
      });
    },
    async saveCall({ commit }, params) {
      await Vue.axios.post("https://us-central1-tappbellopenkey.cloudfunctions.net/makeCall?token=" + params.token + "&idneigh=" + params.idneigh + "&idgroup=" + params.idgroup,{session:params.session,img:params.img})
      .then((response) => {
          if (response.status == 200) {
            //console.log('Response OK 200');
            commit("SET_CALL", response.data.call);
          } else {
            //console.log('Response Error 404');
            commit("SET_CALL", null);
          }
      })
      .catch((error) => {
        console.log('catched error', error);
      });
    },
    async updateCall({ commit }, params) {
      await Vue.axios.post("https://us-central1-tappbellopenkey.cloudfunctions.net/updateCall?token=" + params.token + "&idneigh=" + params.idneigh + "&idgroup=" + params.idgroup,{call:params.call})
      .then((response) => {
          if (response.status == 200) {
            //console.log('Response OK 200');
          } else {
            //console.log('Response Error 404');
          }
      })
      .catch((error) => {
        console.log('catched error', error);
      });
    },
  },
})
